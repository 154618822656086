import React, {useContext, useEffect} from 'react';
import SearchResults from "components/page/search";
import {useRouter} from "next/router";
import {useTranslation} from "tools/i18n";
import {TrackingContext} from "components/providers/tracking";
import fetchFromAPI from "tools/fetchFromAPI";

function SearchPage() {
	const router = useRouter();
	const {query} = router;
	const {actions: trackingActions} = useContext(TrackingContext);
	const {t} = useTranslation(['general']);

	useEffect(() => {
		trackingActions.pageView(t('search'));
	}, [])

	return (
		<SearchResults query={query} router={router}/>
	);
}

SearchPage.getInitialProps = (context) => {
	return fetchFromAPI(context);
}

export default SearchPage;
