import Head from 'next/head';
import React from "react";

export default function MetaTitle({title, metaTitle}) {
	return (
		<Head>
			<title>{metaTitle || title}</title>
			<meta property="og:title" content={metaTitle || title}/>
		</Head>
	);
}