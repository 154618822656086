import Select, {components} from 'react-select';
import {useTranslation} from '../../tools/i18n';
import theme from "../theme";
import React, {useState} from "react";
import {makeStyles} from "@material-ui/styles";
import DropdownIndicatorOpen from "../../public/images/icons/minus.svg";
import DropdownIndicatorClosed from "../../public/images/icons/plus.svg";
import DropdownIndicator from "../../public/images/icons/drop-down.svg";

const useStyles = makeStyles({
	control: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		marginBottom: 15,
		padding: '10px 12px 10px 12px',
		borderRadius: 0,
		backgroundColor: '#f5f5f5',
		cursor: 'pointer',
		'& svg': {
			'&.desktop': {
				[theme.breakpoints.down('lg')]: {
					display: 'none'
				}
			},
			'&.mobile': {
				[theme.breakpoints.up('lg')]: {
					display: 'none'
				}
			}
		},
		[theme.breakpoints.down('md')]: {
			marginTop: -1,
			marginBottom: 0,
			padding: '25px 15px 25px 15px',
			backgroundColor: theme.palette.primary.background,
			borderTop: '1px solid #e0e0e0',
			borderBottom: '1px solid #e0e0e0',
			'&.open': {
				marginBottom: 15,
				paddingBottom: 0,
				borderBottom: 'none'
			}
		}
	},
	placeholder: {
		display: 'flex',
		flexGrow: 1,
		justifyContent: 'space-between',
		'& span': {
			fontSize: 12
		}
	},
	option: {
		display: 'flex',
		alignItems: 'center',
		margin: '2px 0',
		padding: '6px 15px',
		cursor: 'pointer',
		'& label': {
			cursor: 'inherit'
		}
	},
	optionColor: {
		marginTop: -2,
		width: 12,
		height: 12,
		marginRight: 15,
		backgroundColor: "currentcolor",
		borderRadius: '50%',
		boxShadow: '0 0 0 1px #e0e0e0',
		'.selected &': {
			boxShadow: '0 0 0 1px #666, inset 0 0 0 1px white'
		}
	},
	checkbox: {
		width: 12,
		height: 12,
		margin: '0 0 0 auto !important',
		appearance: 'none',
		backgroundColor: '#e0e0e0',
		borderRadius: 0,
		cursor: 'inherit',
		'&:checked': {
			backgroundColor: '#000',
			boxShadow: 'inset 0px 0px 0px 0.75px #000, inset 0px 0px 0px 3px #fff'
		}
	}
}, {name: 'FilterDropdownCheckbox'});

export default function FilterDropdownCheckbox({label, name, filterKey, currentFilter, options, className, onChange}) {
	const classes = useStyles();
	const { t } = useTranslation(['general', 'attributes']);
	const [open, setOpen] = useState(false);
	const topSortedOptions = ['Newport', 'Newport Christmas', 'Artwood', 'Eichholtz'];

	function formatLabel(data) {
		return <label>{data.label}</label>;
	}

	function Control({children, ...props}) {
		return '';
	}

	function Placeholder({children, ...props}) {
		return (
			<div className={classes.placeholder}>{label || t('attributes:' + name)}</div>
		);
	}

	function Option({children, ...props}) {
		return (
			<components.Option {...props} className={[classes.option, props.isSelected ? 'selected' : ''].join(' ')}>
				{props.data.color ? <span className={classes.optionColor} style={{color: props.data.color}}></span> : ''}
				{children}
				{!props.data.color ? <input type="checkbox" value={props.value} checked={props.isSelected} className={classes.checkbox} readOnly={true}/> : ''}
			</components.Option>
		);
	}

	function Indicator({children, ...props}) {
		let isOpen = props.selectProps.menuIsOpen;

		return (
			isOpen ? <DropdownIndicatorOpen width={12}/> : <DropdownIndicatorClosed width={12}/>
		)
	}

	function getDefaultOptions() {
		let defaultOptions = options.filter((option) => {
			if(currentFilter && currentFilter.indexOf(option.value) >= 0) {
				return option;
			}
		});

		return defaultOptions;
	}

	function getSortedOptions() {
		return options.sort((a, b) => {
			if(topSortedOptions.indexOf(a.label) >= 0) {
				if(topSortedOptions.indexOf(b.label) >= 0) {
					if(topSortedOptions.indexOf(a.label) > topSortedOptions.indexOf(b.label)) {
						return 1;
					} else {
						return -1;
					}
				}

				return -1;
			} else if (topSortedOptions.indexOf(b.label) >= 0) {
				return 1;
			}

			if(a.label > b.label) {
				return 1;
			} else if (a.label < b.label) {
				return -1;
			}

			return 0;
		});
	}

	return (
		options.length > 1 &&
		<>
			<div className={[classes.control, open ? 'open' : ''].join(' ')} onClick={(e) => setOpen(!open)}>
				<Placeholder />
				{open ?
					<>
						<DropdownIndicatorOpen className={'desktop'} width={12}/>
						<DropdownIndicator className={'mobile'} width={12}/>
					</> :
					<>
						<DropdownIndicatorClosed className={'desktop'} width={12}/>
						<DropdownIndicator className={'mobile'} style={{transform: 'rotate(-90deg)'}} width={12}/>
					</>
				}
			</div>
			<Select
				isMulti={true}
				hideSelectedOptions={false}
				closeMenuOnSelect={false}
				options={getSortedOptions()}
				value={getDefaultOptions()}
				formatOptionLabel={formatLabel}
				isSearchable={false}
				controlShouldRenderValue={false}
				isClearable={false}
				menuIsOpen={open}
				maxMenuHeight={10000}
				captureMenuScroll={false}
				openMenuOnFocus={false}
				onChange={(data) => {
					let values = data.map((option) => {
						return option.value;
					});

					onChange(filterKey, values);
				}}
				components={{
					IndicatorSeparator: null,
					Placeholder: Placeholder,
					DropdownIndicator: Indicator,
					Option: Option,
					Control: Control
				}}
				styles={{
					control: (provided, state) => {
						delete provided.borderWidth;
						delete provided.borderStyle;
						delete provided.borderColor;
						delete provided.backgroundColor;
						delete provided.cursor;
						delete provided.boxShadow;
						delete provided.minHeight;

						return {
							...provided,
						}
					},
					menu: (provided, state) => {
						delete provided.position;
						delete provided.boxShadow;

						return {
							...provided,
							marginTop: -10
						}
					},
					menuList: (provided, state) => {
						delete provided.maxHeight;

						return {
							...provided
						}
					},
					option: (provided, state) => {
						delete provided.color;
						delete provided.backgroundColor;
						delete provided.padding;
						delete provided.cursor;
						delete provided[':active'];

						return {
							...provided,
							display: 'flex',
							alignItems: 'flex-start',
							backgroundColor: 'transparent',
							color: theme.palette.text.black,
							'& input': {
								margin: '4px 6px 0 0',
								verticalAlign: 'middle'
							}
						}
					},
					dropdownIndicator: (provided, state) => {
						return {
							...provided,
							color: "#666",
							'&:hover': {
								color: "#000",
							}
						}
					},
					clearIndicator: (provided, state) => {
						return {
							...provided,
							padding: '8px 0px 8px 8px'
						}
					}
				}}
				instanceId={'select-' + name}
			/>
		</>
	);
}