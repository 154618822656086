import urlNormalizer from "../tools/urlNormalization";

export function prepareFacets(facets) {
    let facetKeys = Object.keys(facets);
    let formattedFacets = {};

    facetKeys.forEach((facetKey) => {
        let facet = facets[facetKey];

        if(facet.indexOf('min:') >= 0) {
            formattedFacets[facetKey] = {
                min: parseInt(facet.split(',')[0].replace('min:', '')),
                max: parseInt(facet.split(',')[1].replace('max:', ''))
            };
        } else {
            formattedFacets[facetKey] = facet.split(',');
        }
    });

    return  formattedFacets;
}

export function getMainVariant(product) {
    let mainSku = product.key.split('-')[1];
    let mainVariant = product.variants.find((variant) => {
        return variant.key === mainSku;
    });

    let mainVariantInStock = mainVariant ? mainVariant.inStock : false;

    return mainVariant && mainVariantInStock ? mainVariant : product.variants.filter((variant) => variant.inStock)[0] || product.variants[0];
}

export function getProductVariants(product) {
    let allVariants = product.variants.filter((variant) => variant.label).map((variant) => {
        return variant.label;
    });

    return allVariants.length > 0;
}

export function getProductImage(product) {
    let productImages = product.imageInfo?.images;

    if(!productImages || productImages.length <= 0) {
        return { src: '/images/placeholder/product.jpg', width: 550, height: 510 };
    }

    let imageSource = productImages.find((item) => {
        return item.custom.type === 'list';
    })?.sources[0] || productImages[0]?.sources[0];

    if(productImages.length > 1) {
        productImages.forEach((image) => {
            if (image['sources'][0].width === 550) {
                imageSource = image['sources'][0];
            }
        });
    }

    const width = (imageSource.width || 550);
    const height = (imageSource.height || 510);

    return {
        src: imageSource.url,
        width: width,
        height: height,
        aspectRatio: height / width,
    }
}

export function getUUID(product) {
    return product.custom.uuid[0].id;
}

export function getMarketingLabel(product) {
    if(!product?.custom?.marketing_label) {
        return;
    }

    return product?.custom?.marketing_label;
}

export function getStock(product) {
    return product.custom?.in_stock ? parseInt(product.custom.in_stock[0].label) : 0;
}

export function getFastDelivery(product) {
    return !!product.custom?.fast_delivery_available;
}

export function preventFloatRoundingIssues(price, listPrice) {
    let centPrice = parseInt(price * 100);
    let centListPrice = parseInt(listPrice * 100);

    return parseInt(Math.round(100 * (centListPrice - centPrice) / centListPrice));
}